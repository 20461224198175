import React from "react";
import styled, { keyframes } from "styled-components";

export default function WhoAmI() {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      xmlLang="en"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
    >
      <defs>
        <path
          id="textcircle"
          d="M250,400a150,150 0 0,1 0,-300a150,150 0 0,1 0,300Z"
          transform="rotate(12,250,250)"
        />
      </defs>
      <g className="textcircle">
        <text textLength="940">
          <textPath
            xlinkHref="#textcircle"
            ariaLabel="CSS & SVG are awesome"
            textLength="940"
          >
            A Software Engineer &#160;&#9733;&#160; based in India
            &#160;&#9733;&#160;&#160;
          </textPath>
        </text>
      </g>
    </SVG>
  );
}

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const SVG = styled.svg`
  fill: currentColor;
  height: auto;
  transform-origin: center;
  width: 240px;

  text {
    fill: #1c1d20;
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    font-weight: 500;
    text-transform: uppercase;
    font-variant-ligatures: none;
    animation: ${rotate} 15s linear infinite;
    transform-origin: 250px 250px;
    user-select: none;
    word-spacing: 10px;
  }

  .textcircle {
    transition: transform 1s cubic-bezier(0.65, 0, 0.35, 1);
    transform-origin: 250px 250px;
  }

  &:hover .textcircle {
    transform: scale(1.1) rotate(90deg);
  }

  @media (max-width: 940px) {
    display: none;
  }
`;
