import React, { useRef, useState } from "react";
import { styled } from "styled-components";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { useInView } from "react-intersection-observer";

import HomeHeading from "../../components/HomeHeading";
import HomeContainerWrapper from "../../components/HomeContainerWrapper";
import skills from "../../assets/docs/skills.json";
import Experiences from "./Experiences";

export default function About() {
  return (
    <Container id="about">
      <HomeContainerWrapper>
        <HomeHeading>What Makes Me Tick?</HomeHeading>
        <p>
          Since I was young, I've been obsessed with computers, always curious
          about what's hidden behind the curtain. This fascination steered me
          towards Computer Science in high school, where I discovered the magic
          of coding and its potential. With a strong foundation in C++, I
          pursued an undergraduate degree in Computer Science and Engineering.
          During my studies, I explored various programming languages, mastering
          Java and Python, and dove deep into algorithms and data structures.
          Web & mobile apps development, IoT, cloud computing—I dove headfirst
          into these different areas of computer science. But my hunger for
          knowledge isn't satisfied. Now, I'm setting my sights on AI, a field
          I'm truly passionate about, and I'm in the transition process of
          getting myself into the domain. I'm a hands-on learner and believer in
          the mantra—
          <i>Build, Break & Learn</i>. Every coding project is a step forward in
          my journey to understand and contribute to the ever-evolving world of
          technology.
        </p>
        <MySkills />
        <Experiences />
      </HomeContainerWrapper>
    </Container>
  );
}

function MySkills() {
  const navRef = useRef(null);
  const [active, setActive] = useState(0);
  const [disableNavButton, setDisableNavButton] = useState("left");

  const scroll = (opt) => {
    if (!navRef.current) return;

    if (opt === "left") {
      navRef.current.scrollLeft -= 250;
    } else {
      navRef.current.scrollLeft += 250;
    }
  };

  const handleScroll = () => {
    if (navRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = navRef.current;
      if (scrollLeft === 0) {
        setDisableNavButton("left");
      } else if (Math.ceil(scrollLeft) >= scrollWidth - clientWidth) {
        setDisableNavButton("right");
      } else {
        setDisableNavButton("none");
      }
    }
  };

  return (
    <SkillsContainer>
      <h3>Tech Fluency</h3>
      <SkillsContainerWrapper>
        {disableNavButton !== "left" && (
          <button
            onClick={() => scroll("left")}
            className="skills-btn skills-btn-l"
          >
            <IoMdArrowDropleft />
          </button>
        )}
        <LayoutGroup>
          <ul ref={navRef} onScroll={handleScroll} className="skills-nav">
            {skills.map((item, index) => (
              <SkillItem
                key={index}
                index={index}
                item={item}
                active={active}
                setActive={setActive}
              />
            ))}
          </ul>
        </LayoutGroup>
        {disableNavButton !== "right" && (
          <button
            onClick={() => scroll("right")}
            className="skills-btn skills-btn-r"
          >
            <IoMdArrowDropright />
          </button>
        )}
      </SkillsContainerWrapper>
      {skills.length > 0 ? (
        <AnimatePresence key={active}>
          <motion.div
            transition={{ duration: 0.5 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="skills-details"
          >
            <ul>
              {skills[active].skills.map((item, index) => (
                <li key={index}>
                  <span>{item.name}</span>
                  <SkillProgressBar>
                    <motion.div
                      transition={{ delay: 0.15, duration: 0.5 }}
                      initial={{ width: 0 }}
                      animate={{ width: `${item.proficiency}%` }}
                      className="skill-progress-fill"
                    />
                  </SkillProgressBar>
                </li>
              ))}
            </ul>
          </motion.div>
        </AnimatePresence>
      ) : (
        ""
      )}
    </SkillsContainer>
  );
}

function SkillItem({ item, index, active, setActive }) {
  const ref = useRef(null);
  const { inView } = useInView({
    threshold: 1,
    ref,
  });

  const activeSkill = (index) => {
    if (!inView && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    setActive(index);
  };

  return (
    <motion.li
      ref={ref}
      className={index === active ? "nav-selected" : ""}
      onClick={() => activeSkill(index)}
      key={index}
      animate
    >
      {item.name}
      {index === active && (
        <motion.div className="underline" layoutId="underline" />
      )}
    </motion.li>
  );
}

const Container = styled.div`
  width: 100%;

  p {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    color: #474747;
    line-height: 1.7em;
    text-align: justify;
  }

  h3 {
    color: #1c1d20;
    font-family: "Poppins", sans-serif;
    font-size: 34px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

const SkillsContainer = styled.div`
  margin-top: 40px;
  width: 100%;

  .skills-nav {
    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: flex-end;
    margin-top: 50px;
    gap: 10px;
    border-bottom: 1px solid #f1f1f1;
    scroll-behavior: smooth;

    li {
      list-style: none;
      font-family: "Poppins", sans-serif;
      color: #a6a6a6;
      padding: 4px 8px;
      font-size: 14px;
      text-align: center;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
      transition: transform 0.2s ease-out;
    }

    .underline {
      width: 100%;
      height: 3px;
      border-radius: 4px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      background: #474747;
    }

    .nav-selected {
      color: #474747;
      font-weight: 500;
    }
  }

  .skills-details {
    padding: 80px 0 40px 0;
    min-height: 120px;
    width: 94%;
    margin: 0 auto;

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 50px;
      width: 100%;

      li {
        font-size: 14px;
        width: calc(50% - 40px);
        font-family: "Poppins", sans-serif;
      }
    }
  }

  @media (max-width: 768px) {
    .skills-nav {
      gap: 5px;
    }

    .skills-nav li {
      font-size: 13px;
    }

    .skills-nav .nav-selected {
    }

    .skills-details ul {
      flex-direction: column;
      align-items: center;

      li {
        width: 80%;
      }
    }
  }
`;

const SkillProgressBar = styled.div`
  position: relative;
  height: 8px;
  background-color: #dadada;
  margin-top: 3px;
  border-radius: 4px;
  overflow: hidden;

  .skill-progress-fill {
    position: absolute;
    background-color: #474747;
    left: 0;
    bottom: 0;
    top: 0;
    width: ${(props) => `${props.progress}%`};
  }
`;

const SkillsContainerWrapper = styled.div`
  position: relative;
  padding: 0 30px;

  .skills-btn {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    background: none;
    border: none;
    outline: none;
    opacity: 0.7;

    svg {
      transform: scale(2.6);
      color: #474747;
    }
  }

  .skills-btn-l {
    left: 0;
  }

  .skills-btn-r {
    right: 0;
  }

  @media (max-width: 768px) {
    .skills-btn {
      height: 24px;
      svg {
        transform: scale(2.4);
        color: #474747;
      }
    }
  }
`;
