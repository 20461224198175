import React from "react";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { IoOpenOutline } from "react-icons/io5";

import projects from "../../assets/docs/projects.json";

export default function Projects({ len = projects.length }) {
  return (
    <ProjectContainer>
      {projects.slice(0, len).map((item, index) => (
        <div key={index}>
          <Project title={item.what} to={`/projects/${item.nav}`}>
            <div className="heading">
              <h4>{item.name}</h4>
              <IoOpenOutline />
            </div>
          </Project>
        </div>
      ))}
    </ProjectContainer>
  );
}

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Project = styled(Link)`
  width: 100%;
  font-family: "Poppins", sans-serif;
  list-style: none;
  text-decoration: none;
  color: #474747;
  display: flex;
  flex-direction: column;
  transform-origin: center;
  border-bottom: 1px solid #a9a9a9;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #474747;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    padding: 50px 30px;

    h4 {
      font-size: 42px;
      font-weight: 400;
    }

    svg {
      font-size: 34px;
      flex-shrink: 0;
    }
  }

  &:hover .heading {
    padding: 50px 20px;
    color: #000;
  }

  @media (max-width: 768px) {
    .heading {
      padding: 30px 15px;
    }

    .heading h4 {
      font-size: 20px;
    }

    .heading svg {
      font-size: 24px;
    }

    &:hover .heading {
      padding: 30px 10px;
    }
  }
`;
