import React from "react";
import { styled } from "styled-components";

import { HomeNavigation } from "../../components/Navbar";
import WhoAmI from "../../components/WhoAmI";

export default function index() {
  return (
    <Container id="home">
      <HomeNavigation />
      <div className="home-wrapper">
        <div className="intro">
          <span>Hello,</span>
          <span>im</span>
          <span>Siva.</span>
        </div>
        <WhoAmI />
        <p>
          A Software Engineer<br></br>based in India
        </p>
      </div>
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .home-wrapper {
    flex: 1;
    min-height: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .intro span {
    font-size: 140px;
    font-weight: 700;
    line-height: 0.9em;
    font-style: normal;
    color: #1c1d20;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
  }

  p {
    font-family: "Poppins", sans-serif;
    line-height: 1.3em;
    color: #474747;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    display: none;
    word-spacing: 4px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }

  @media (max-width: 768px) {
    .intro span {
      font-size: 90px;
    }
  }
  @media (max-width: 940px) {
    .home-wrapper {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 25px;
    }
    p {
      writing-mode: unset;
      transform: rotate(0);
      font-size: 14px;
      display: block;
    }
  }
`;
